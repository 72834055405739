<template>
  <div>
    <Navigation
      :show-language-switcher="true"
      :show-login="false"
      :show-user-menu="false"
    ></Navigation>

    <div
      class="flex flex-col items-center justify-items-center text-center mt-28 mb-28 px-6 mx-auto max-w-4xl"
    >
      <img
        :src="require(`../assets/${logoName}-logo.png`)"
        alt="logo"
        class="md:block h-auto w-10/12"
      />
      <p class="mt-8 text-2xl leading-9 font-bold text-gray-900">
        Logging You In To The Client Portal
      </p>
      <p class="mt-6 text-sm font-medium leading-5 text-gray-700">
        Thank you for opening an account with Blackstone Futures. BlackStone
        Futures (Pty) is a duly appointed juristic representative of Trade
        Nation (Pty) Limited, which is an authorised Financial Services Provider
        under the Financial Advisory and Intermediary Services Act No 37 of 2002
        - FSP#49846.
      </p>
      <div class="account-summary-loader mt-10"></div>
    </div>

    <Footer class="mx-auto" />
  </div>
</template>
<script>
import Navigation from "@/components/Navigation";
import Footer from "@/components/Footer";

export default {
  name: "Login",
  components: {
    Navigation,
    Footer,
  },
  data() {
    return {
      logoName: process.env.VUE_APP_WHITELABEL,
      username: "",
      password: "",
      isDisabled: false,
    };
  },
  created() {
    setTimeout(() => this.$router.replace("/"), 10000);
  },
};
</script>